// ** React Imports
import React, { useEffect, useCallback, useRef, useState, ChangeEvent } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** MUI Imports
import Box from '@mui/material/Box'
import MuiDialog from '@mui/material/Dialog'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'

// ** Modules Imports
import { useIntl } from 'react-intl'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Configs Imports
import themeConfig from 'src/configs/themeConfig'

// ** Store
import searchStore, { getSearchFaqs, getSearchNews, getSearchReleases, resetSearch } from 'src/state-manager/search'
import { useStore } from 'effector-react'
import { CircularProgress } from '@mui/material'

interface Props {
  settings: Settings
}

interface NoResultProps {
  formatMessage: any
  value: string
}

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& fieldset': {
    border: 0
  },
  '& + .MuiAutocomplete-popper': {
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
      height: '100%',
      maxHeight: 'inherit',
      '& .MuiListSubheader-root': {
        top: 0,
        fontWeight: 400,
        lineHeight: '15px',
        fontSize: '0.75rem',
        letterSpacing: '1px',
        color: theme.palette.text.disabled
      }
    },
    '& .MuiAutocomplete-paper': {
      border: 0,
      height: '100%',
      borderRadius: 0,
      boxShadow: 'none'
    },
    '& .MuiListItem-root.suggestion': {
      padding: 0,
      '& .MuiListItemSecondaryAction-root': {
        display: 'flex'
      },
      '&.Mui-focused.Mui-focusVisible, &:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '& .MuiListItemButton-root: hover': {
        backgroundColor: 'transparent'
      },
      '&:not(:hover)': {
        '& .MuiListItemSecondaryAction-root': {
          display: 'none'
        },
        '&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)': {
          '& .MuiListItemSecondaryAction-root': {
            display: 'flex'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root': {
            display: 'none'
          }
        }
      }
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'grid',
      minHeight: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(10)
    }
  }
}))

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)'
  },
  '& .MuiDialog-paper': {
    overflow: 'hidden',
    '&:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 420
    }
  }
})

const NoResult = ({ value, formatMessage }: NoResultProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ mb: 2.5, color: 'text.primary' }}>
        <Icon icon='mdi:file-remove-outline' fontSize='5rem' />
      </Box>
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {formatMessage({ id: 'no-results-for' }, { text: value })}
      </Typography>
    </Box>
  )
}

const PressSearch = () => {
  const { formatMessage } = useIntl()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ mb: 2.5, color: 'text.primary' }}>
        <Icon icon='mdi:search' fontSize='5rem' />
      </Box>
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {formatMessage({ id: 'press-search' })}
      </Typography>
    </Box>
  )
}

const AutocompleteComponent = ({ settings }: Props) => {
  // ** States
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  // ** Hooks & Vars
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const router = useRouter()
  const { layout } = settings
  const wrapper = useRef<HTMLDivElement>(null)

  const matchMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'))

  // ** Stores
  const { releases, news, faqs, loadingReleases, loadingNews, loadingFaqs } = useStore(searchStore.search)

  // ** Categories
  const categoryTitle: { [k: string]: string } = {
    releases: formatMessage({ id: 'search-releases' }),
    news: formatMessage({ id: 'search-news' }),
    faqs: formatMessage({ id: 'search-faqs' })
  }

  const timeoutLoading = useRef<any>()
  useEffect(() => {
    clearTimeout(timeoutLoading.current)

    timeoutLoading.current = setTimeout(() => {
      setLoading(loadingReleases || loadingNews || loadingFaqs)
    }, 300)
  }, [loadingReleases, loadingNews, loadingFaqs])

  const options = loading
    ? []
    : [
        ...releases.map((i: any) => ({
          title: i.data.name,
          url: `/release/edit/${i._id}`,
          category: 'releases'
        })),
        ...news.map((i: any) => ({
          title: i.data.title,
          url: `/news/${i.data.url}`,
          category: 'news'
        })),
        ...faqs.map((i: any) => ({
          title: i.data.title,
          url: `/help-center/${i._id}`,
          category: 'faqs'
        }))
      ]

  // Get all data using API
  const timeoutFetching = useRef<any>()

  useEffect(() => {
    if (!searchValue) {
      resetSearch()

      return
    }

    clearTimeout(timeoutFetching.current)

    setLoading(true)

    timeoutFetching.current = setTimeout(() => {
      const regExpSearch = { $regex: `${searchValue}`, $options: 'i' }
      getSearchReleases({
        limit: 5,
        query: {
          [`data.name`]: regExpSearch
        }
      })

      getSearchNews({
        limit: 5,
        query: {
          [`data.title`]: regExpSearch
        }
      })

      getSearchFaqs({
        limit: 5,
        query: {
          [`data.title`]: regExpSearch
        }
      })
    }, 400)
  }, [searchValue])

  useEffect(() => {
    if (!openDialog) {
      setSearchValue('')
    }
  }, [openDialog])

  useEffect(() => {
    setIsMounted(true)

    return () => setIsMounted(false)
  }, [])

  // Handle click event on a list item in search result
  const handleOptionClick = (obj: any) => {
    setSearchValue('')
    setOpenDialog(false)
    if (obj.url) {
      router.push(obj.url)
    }
  }

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (!openDialog && event.ctrlKey && event.which === 191) {
        setOpenDialog(true)
      }
    },
    [openDialog]
  )

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      // ** ESC key to close searchbox
      if (openDialog && event.keyCode === 27) {
        setOpenDialog(false)
      }
    },
    [openDialog]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp, handleKeydown])

  if (!isMounted) {
    return null
  } else {
    return (
      <Box
        ref={wrapper}
        onClick={() => !openDialog && setOpenDialog(true)}
        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
      >
        <IconButton color='inherit' sx={layout === 'vertical' ? { mr: 1, ml: -2.75 } : {}}>
          <Icon icon='mdi:magnify' />
        </IconButton>
        {layout === 'vertical' && !matchMobile && (
          <Typography sx={{ userSelect: 'none', color: 'text.disabled' }}>
            {formatMessage({ id: 'search' })} (Ctrl+/)
          </Typography>
        )}
        {openDialog && (
          <Dialog fullWidth open={openDialog} fullScreen={fullScreenDialog} onClose={() => setOpenDialog(false)}>
            <Box sx={{ top: 0, width: '100%', position: 'sticky', zIndex: 10 }}>
              <Autocomplete
                loadingText={
                  <Box
                    sx={{
                      width: '100%',
                      height: 300,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
                loading={loading}
                autoHighlight
                disablePortal
                options={options}
                id='appBar-search'
                isOptionEqualToValue={() => true}
                onInputChange={(event, value: string) => setSearchValue(value)}
                onChange={(event, obj) => handleOptionClick(obj as any)}
                noOptionsText={<NoResult formatMessage={formatMessage} value={searchValue} />}
                getOptionLabel={(option: any | unknown) => (option as any).title}
                groupBy={(option: any | unknown) => (searchValue.length ? categoryTitle[(option as any).category] : '')}
                sx={{
                  '& + .MuiAutocomplete-popper': {
                    '& .MuiListSubheader-root': { p: theme.spacing(3.75, 6, 0.75) },
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 69px)',
                    borderTop: `1px solid ${theme.palette.divider}`,
                    height: fullScreenDialog ? 'calc(100vh - 69px)' : 351,
                    ...(!searchValue.length ? { height: 0 } : {})
                  }
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <TextField
                      {...params}
                      value={searchValue}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                      inputRef={input => {
                        if (input) {
                          if (openDialog) {
                            input.focus()
                          } else {
                            input.blur()
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: { p: `${theme.spacing(3.75, 6)} !important` },
                        startAdornment: (
                          <InputAdornment position='start' sx={{ color: 'text.primary' }}>
                            <Icon icon='mdi:magnify' />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            onClick={() => setOpenDialog(false)}
                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                          >
                            {!matchMobile && <Typography sx={{ mr: 2.5, color: 'text.disabled' }}>[esc]</Typography>}
                            <IconButton size='small' sx={{ p: 1 }}>
                              <Icon icon='mdi:close' fontSize={20} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )
                }}
                renderOption={(props, option: any | unknown) => {
                  return searchValue.length ? (
                    <ListItem
                      {...props}
                      key={(option as any).title}
                      className={`suggestion ${props.className}`}
                      onClick={() => handleOptionClick(option as any)}
                      secondaryAction={<Icon icon='mdi:subdirectory-arrow-left' fontSize={20} />}
                      sx={{
                        '& .MuiListItemSecondaryAction-root': {
                          '& svg': {
                            cursor: 'pointer',
                            color: 'text.disabled'
                          }
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          py: 2.5,
                          px: `${theme.spacing(6)} !important`,
                          '& svg': { mr: 2.5, color: 'text.primary' }
                        }}
                      >
                        <Icon fontSize={20} icon={(option as any).icon || themeConfig.navSubItemIcon} />
                        <Typography variant='body2' sx={{ color: 'text.primary' }}>
                          {(option as any).title}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null
                }}
              />
            </Box>
            {searchValue.length === 0 ? (
              <Box
                sx={{
                  p: 10,
                  display: 'grid',
                  overflow: 'auto',
                  borderTop: `1px solid ${theme.palette.divider}`,
                  height: fullScreenDialog ? 'calc(100vh - 69px)' : '100%'
                }}
              >
                <PressSearch />
              </Box>
            ) : null}
          </Dialog>
        )}
      </Box>
    )
  }
}

export default AutocompleteComponent
