import axios from 'axios'
import { createStore, createEffect } from 'effector'
import globalEndpointsConfig from 'src/configs/global-endpoints'

const getGlobalNoticeData = createEffect(async () => {
  const { data } = await axios.get(globalEndpointsConfig.getGlobalNoticeData())
  const dateLastVisibleNotice: string | null = localStorage.getItem('dateLastVisibleNotice')

  if (data.displayIntervalInHours !== 0 && dateLastVisibleNotice && data.active) {
    const pastIntervalInHours = (Date.now() - Number(dateLastVisibleNotice)) / (1000 * 60 * 60)
    const timeForDisplayHasCome = data.displayIntervalInHours < pastIntervalInHours

    return { ...data, active: timeForDisplayHasCome ? data.active : false }
  }

  return data
})

const switchActivityStatusGlobalNotice = createEffect(async (payload: boolean) => {
  localStorage.setItem('dateLastVisibleNotice', Date.now().toString())

  return payload
})

const globalNotificeData = createStore<{
  data: { image: string | null; text: string }
  active: boolean
}>({
  data: { image: null, text: '' },
  active: false
})
  .on(getGlobalNoticeData.doneData, (state, newState: any) => {
    return {
      ...state,
      ...newState,
      loaded: true
    }
  })
  .on(switchActivityStatusGlobalNotice, (state, payload) => {
    return {
      ...state,
      active: payload
    }
  })

export { getGlobalNoticeData, switchActivityStatusGlobalNotice }

export default globalNotificeData
