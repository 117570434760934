import globalNotificeData, { switchActivityStatusGlobalNotice, getGlobalNoticeData } from './notice'
import shared, { getSharedDetail } from './shared'
import techBreakNotificationData, { getTechBreakNotificationData } from './tech-breack-notifications'
import locale, { setLocale } from './locale'

export {
  getTechBreakNotificationData,
  getGlobalNoticeData,
  switchActivityStatusGlobalNotice,
  getSharedDetail,
  setLocale
}

export default {
  globalNotificeData,
  techBreakNotificationData,
  shared,
  locale
}
