import apiConfig from './api'

type ConfigType = {
  uploadEndpoint: string
  tus: {
    enabled: boolean
    endpoint: string
    chunkSize: number
  }
}

export default {
  uploadEndpoint: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader`,
  tus: {
    enabled: true,
    endpoint: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader/chunk/default/s3/`,
    chunkSize: 64 * 1024 * 1024
  }
} as ConfigType
