import axios from 'axios'
import { createStore, createEffect } from 'effector'
import globalEndpointsConfig from 'src/configs/global-endpoints'

const getTechBreakNotificationData = createEffect(async () => {
  const { data } = await axios.get(globalEndpointsConfig.getTechBreackNotificationData())

  return data
})

const techBreakNotificationData = createStore<{
  startAt: string
  endAt: string
  loaded: boolean
}>({
  startAt: '',
  endAt: '',
  loaded: false
}).on(getTechBreakNotificationData.doneData, (state, newState: any) => {
  return {
    ...state,
    ...newState,
    loaded: true
  }
})

export { getTechBreakNotificationData }

export default techBreakNotificationData
