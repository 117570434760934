// ** React Imports
import { ReactNode, useEffect, Fragment, useMemo } from 'react'

// ** Next Imports
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { GoogleTagManager } from '@next/third-parties/google'

// ** MUI Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports

import { defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'

// ** Third Party Import
import { YandexMetricaProvider } from 'next-yandex-metrica'
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import AclGuard from 'src/@core/components/auth/AclGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import GuestGuard from 'src/@core/components/auth/GuestGuard'
import WindowWrapper from 'src/@core/components/window-wrapper'
import SnackbarAlerts from 'src/layouts/components/snackbar'
import TechBreakNotification from 'src/@core/components/tech-breack-notification'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Contexts
import { AuthProvider } from 'src/context/AuthContext'
import { HistoryProvider } from 'src/context/HistoryContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Modules Imports
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ru'
import { IntlProvider, useIntl } from 'react-intl'
import ResizeObserver from 'resize-observer-polyfill'
import { FlagProvider, useFlag } from '@unleash/proxy-client-react'
import { useStore } from 'effector-react'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/iconify-bundle/icons-bundle-react'

// ** Configs Imports
import featureFlagConfig from 'src/configs/feature-flag'
import localizationConfig from 'src/configs/platform-localizations'

// ** Source code Imports
import globalStore from 'src/state-manager/global'
import ErrorPage from 'src/views/pages/error-page'
import TechnicalBreackPage from 'src/views/pages/technical-breack'
import UpdateBrowser from 'src/layouts/components/update-browser'
import CookiesPolicy from 'src/layouts/components/cookies-policy'

// ** Global css styles
import '../../styles/globals.css'
import '../assets/style.css'

// ** Enable dayjs plugins
dayjs.extend(utc)
dayjs.extend(timezone)

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
  settings: any
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard, settings }: GuardProps) => {
  const { pathname } = useRouter()

  const { formatMessage } = useIntl()

  const isOpenedFinanceReports = useFlag('is_opened_finance_reports')
  const isTechnicalBreak = useFlag('is_closed_for_technical_break')

  if (!isOpenedFinanceReports && (pathname === '/finance-reports/[id]' || pathname === '/finance-reports'))
    return (
      <ErrorPage
        code={404}
        title={formatMessage({ id: 'page-not-found' })}
        description={formatMessage({ id: 'page-not-found-description' })}
      />
    )

  if (isTechnicalBreak) return <TechnicalBreackPage />

  if (guestGuard) {
    return <GuestGuard fallback={<Spinner settings={settings} />}>{children}</GuestGuard>
  } else if (authGuard) {
    return <AuthGuard fallback={<Spinner settings={settings} />}>{children}</AuthGuard>
  } else {
    return <Fragment>{children}</Fragment>
  }
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const { language } = useStore(globalStore.locale)

  const languageMessages = useMemo(() => {
    const currentLanguage = localizationConfig.find(({ value }) => value === language)?.options

    return currentLanguage || localizationConfig[0]?.options
  }, [language])

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false
  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? false

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  useEffect(() => {
    if (window) window.ResizeObserver = ResizeObserver
  }, [])

  const metaTitle = `${themeConfig.templateName} — сервис, где музыка зарабатывает`
  const metaDescription =
    'Продвижение и дистрибьюция вашей музыки по 150+ сервисам. Продавай свои треки через дистрибьютора музыки Silk, специально для музыкантов и музыкальных лейблов, продюсеров и music менеджеров.'
  const originHost = `https://${process.env.NEXT_PUBLIC_DOMAIN}`

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{metaTitle}</title>
        <meta name='description' content={metaDescription} />

        <meta itemProp='name' content={metaTitle} />
        <meta itemProp='description' content={metaDescription} />
        <meta itemProp='image' content={`${originHost}/images/og-icon-300x300.jpg`} />

        <meta property='og:url' content={originHost} />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content={process.env.NEXT_PUBLIC_DOMAIN} />
        <meta property='og:title' content={metaTitle} />
        <meta property='og:description' content={metaDescription} />
        <meta property='og:image' content={`${originHost}/images/og-image.jpg`} />
        <meta property='og:image:secure_url' content={`${originHost}/images/og-image.jpg`} />
        <meta property='og:image:width' content='968' />
        <meta property='og:image:height' content='504' />
        <meta property='og:image:type' content='image/jpg' />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={metaTitle} />
        <meta name='twitter:description' content={metaDescription} />
        <meta name='twitter:image' content={`${originHost}/images/og-icon-500x500.jpg`} />

        <meta name='keywords' content={`${themeConfig.templateName}, Publishing Platform, Admin, Platform`} />
        <meta name='viewport' content='initial-scale=1, width=device-width' />

        <link rel='apple-touch-icon' sizes='60x60' href={`${originHost}/images/apple-touch-icon-60x60.jpg`} />
        <link rel='apple-touch-icon' sizes='72x72' href={`${originHost}/images/apple-touch-icon-72x72.jpg`} />
        <link rel='apple-touch-icon' sizes='120x120' href={`${originHost}/images/apple-touch-icon-120x120.jpg`} />
        <link rel='apple-touch-icon' sizes='152x152' href={`${originHost}/images/apple-touch-icon-152x152.jpg`} />
        <link rel='apple-touch-icon' sizes='180x180' href={`${originHost}/images/apple-touch-icon-180x180.jpg`} />
        <link rel='icon' href='/images/favicon.png' type='image/png' />
        <link rel='shortcut icon' href={`${originHost}/images/favicon.svg`} type='image/svg+xml' />
      </Head>
      <YandexMetricaProvider
        tagID={94875389}
        initParameters={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        }}
      >
        <FlagProvider config={featureFlagConfig}>
          <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs}>
            <IntlProvider messages={languageMessages} locale={language} defaultLocale={language}>
              <AuthProvider>
                <HistoryProvider>
                  <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                    <SettingsConsumer>
                      {({ settings }) => {
                        return (
                          <ThemeComponent settings={settings}>
                            <WindowWrapper>
                              <TechBreakNotification />
                              <SnackbarAlerts />
                              <Guard settings={settings} authGuard={authGuard} guestGuard={guestGuard}>
                                <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard} authGuard={authGuard}>
                                  {getLayout(<Component {...pageProps} />)}
                                </AclGuard>
                              </Guard>
                            </WindowWrapper>
                            <ReactHotToast>
                              <Toaster
                                position={settings.toastPosition}
                                toastOptions={{ className: 'react-hot-toast' }}
                              />
                            </ReactHotToast>
                            <UpdateBrowser />
                            <CookiesPolicy />
                          </ThemeComponent>
                        )
                      }}
                    </SettingsConsumer>
                  </SettingsProvider>
                </HistoryProvider>
              </AuthProvider>
            </IntlProvider>
          </LocalizationProvider>
        </FlagProvider>
        <GoogleTagManager gtmId='GTM-MT7T97JR' />
      </YandexMetricaProvider>
    </CacheProvider>
  )
}

export default App
