// ** Locales
import ru from 'src/locale/ru.json'
import en from 'src/locale/en.json'

interface LanguageType {
  label: string
  value: string
  country: string
  options: Record<string, string>
}

const platformLanguages: LanguageType[] = [
  { label: 'Русский', value: 'ru', country: 'RUS', options: ru },
  { label: 'English', value: 'en', country: 'GBR', options: en }
]

export default platformLanguages
