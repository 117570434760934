import { useState, useRef } from 'react'

// ** MUI Imports
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Modules Imports
import { useStore } from 'effector-react'
import CountrieFlag from 'react-world-flags'

// ** Source code Imports
import globalStore, { setLocale } from 'src/state-manager/global'
import localizationConfig from 'src/configs/platform-localizations'

const LangSwitcher = () => {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const { language } = useStore(globalStore.locale)

  const handleToggle = () => setOpen(prevState => !prevState)

  const handleClose = (evt: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(evt.target as HTMLElement)) return

    setOpen(false)
  }

  const handleChangeLanguage = (evt: Event | React.SyntheticEvent, lang: string) => {
    setLocale(lang)
    handleClose(evt)
  }

  const handleListKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Tab') {
      evt.preventDefault()
      setOpen(false)
    } else if (evt.key === 'Escape') {
      setOpen(false)
    }
  }

  return (
    <>
      <IconButton ref={anchorRef} color='inherit' aria-haspopup='true' onClick={handleToggle}>
        <Icon icon='mdi:language' />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  {localizationConfig.map(item => (
                    <MenuItem
                      key={item.value}
                      onClick={evt => handleChangeLanguage(evt, item.value)}
                      selected={item.value === language}
                    >
                      <CountrieFlag width={16} code={item.country} />
                      <Typography px={1}>{item.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default LangSwitcher
