import axios from 'axios'
import { createStore, createEffect } from 'effector'
import globalEndpointsConfig from 'src/configs/global-endpoints'

const getSharedDetail = createEffect(async () => {
  const res = await axios.post(globalEndpointsConfig.getKeyValueTable(), {
    query: {
      $and: [{ 'data.groupName': 'shared' }]
    }
  })

  const data: Record<string, any> = {}

  res.data?.data.forEach(
    (item: {
      data: {
        groupName: string
        key: string
        value?: string
      }
    }) => {
      const { key, value } = item.data
      data[key] = value
    }
  )

  return data
})

const shared = createStore<{
  data: Record<string, any>
  loading: boolean
}>({
  loading: true,
  data: {}
})
  .on(getSharedDetail, (state: any) => {
    return {
      data: state.data,
      loading: true
    }
  })
  .on(getSharedDetail.doneData, (state: any, payload) => {
    return {
      ...state,
      loading: false,
      data: payload
    }
  })

export { getSharedDetail }

export default shared
