// ** React Imports
import { useEffect, useRef, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Modules Imports
import { ReadPermissionsType } from '@emd-cloud/react-components/dist/hooks/useUploader'
import { useUploader, useDropzone } from '@emd-cloud/react-components'
import { useIntl } from 'react-intl'
import Cookies from 'js-cookie'

// ** Source code imports
import apiConfig from 'src/configs/api'
import authConfig from 'src/configs/auth'
import uploaderConfig from 'src/configs/uploader'
import { createAlert } from 'src/state-manager/snackbar'
import { CircularProgress } from '@mui/material'

const limit = 10

const onErrorMaximumAttachFiles = () =>
  createAlert({
    autoHideDuration: 2500,
    action: false,
    open: true,
    message: {
      type: 'formatMessage',
      value: 'error-maximum-attach-files',
      opts: { count: limit }
    },
    disableTranslation: true,
    variant: 'filled',
    alertSeverity: 'error',
    actionButton: false
  })

interface IProps {
  onUpdate: (data: any) => void
  onRemove?: (index: number) => void
  files: any[]
}

const AttachFile = ({
  onUpdate,
  onRemove = () => {
    /* Empty */
  },
  files: filesData
}: IProps) => {
  const { formatMessage } = useIntl()

  const anchorRef = useRef<any>(null)

  const [open, setOpen] = useState(false)

  const { uploadFiles, isProccess } = useUploader({
    options: {
      apiUrl: apiConfig.apiEndpoint,
      app: apiConfig.space,
    },
    headers: {
      Authorization: `${authConfig.headerTokenKeyName} ${Cookies.get(authConfig.storageTokenKeyName)!}`
    },
    chunkSize: uploaderConfig.tus.chunkSize,
    readPermission: 'onlyAppStaff' as ReadPermissionsType,
    onUpdate: (files) => {
      const uploadSuccessFiles = files.filter(({ status }) => status === 'success')
      const uploadFailedFiles = files.filter(({ status }) => status === 'failed')

      const uploadingIsFinished = (uploadSuccessFiles.length + uploadFailedFiles.length) === files.length

      if (uploadingIsFinished) {
        if (!!uploadFailedFiles.length) {
          createAlert({
            autoHideDuration: 3000,
            action: false,
            open: true,
            message: { type: 'formatMessage', value: 'error-by-upload-files' },
            variant: 'filled',
            alertSeverity: 'error',
            actionButton: false
          })
        }
  
        if (!!uploadSuccessFiles.length) {
          onUpdate(uploadSuccessFiles)
  
          createAlert({
            autoHideDuration: 2500,
            action: false,
            open: true,
            message: { type: 'formatMessage', value: 'files-uploaded-success' },
            variant: 'filled',
            alertSeverity: 'success',
            actionButton: false
          })
        }

        return
      }
    }
  })

  const { rootProps, inputProps } = useDropzone({
    accept: {
      'image/*': [],
      'text/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/json': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    },
    multiple: true,
    disabled: isProccess || filesData.length >= limit,
    onDroped: (files) => {
      if ((files.length + filesData.length) > limit) {
        onErrorMaximumAttachFiles()

        return
      }

      uploadFiles(files)
    }
  })

  useEffect(() => {
    const clickOutside = (evt: MouseEvent) => {
      if (!anchorRef?.current?.contains(evt.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('click', clickOutside)
    }
  }, [anchorRef])

  return (
    <Box ref={anchorRef} sx={{ position: 'relative', width: '50%' }}>
      <Box
        sx={{
          width: 280,
          bgcolor: 'background.paper',
          position: 'absolute',
          bottom: '100%',
          mb: 4,
          visibility: open ? 'visible' : 'hidden',
          opacity: open ? 1 : 0,
          transform: `translate(${open ? '0,0' : '-25%,50%'}) scale(${open ? 1 : 0.5})`,
          transitionProperty: 'opacity scale visibility',
          transitionDuration: '150ms',
          zIndex: 2,
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <List
          sx={{
            width: 1,
            p: 2,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 300
          }}
        >
          {!filesData.length && (
            <Typography sx={{ textAlign: 'center', mb: 2 }} color='text.disabled'>
              {formatMessage({ id: 'empty' })}
            </Typography>
          )}
          {filesData.map(({ name }, index: number) => (
            <ListItem key={index}>
              <Icon icon='mdi:file' />
              <Typography
                sx={{
                  width: 'calc(100% - 72px)',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'pre',
                  mx: 2
                }}
              >
                {name}
              </Typography>
              <IconButton size='small' onClick={() => setTimeout(() => onRemove(index))}>
                <Icon icon='mdi:trash' />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          sx={{ width: 1, borderRadius: 0 }}
          component='div'
          variant='contained'
          color='primary'
          disabled={isProccess || filesData.length >= limit}
          {...rootProps}
        >
          {isProccess ? <CircularProgress size={24} /> : <Icon icon='mdi:upload' />}
          <input id='upload-file' {...inputProps} hidden />
        </Button>
      </Box>
      {!!filesData.length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 100,
            width: 16,
            height: 16,
            backgroundColor: 'white',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            transform: 'translate(25%, -50%)',
            zIndex: 1
          }}
        >
          <Typography color='black' variant='body3' lineHeight={0.5} fontWeight={400}>
            {filesData.length}
          </Typography>
        </Box>
      )}
      <Button
        variant='contained'
        color='secondary'
        sx={theme => ({
          width: 1,
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.common.black,
          color: theme.palette.text.primary,
          borderRadius: 0,
          '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.common.dark,
            color: theme.palette.text.primary
          }
        })}
        onClick={() => setOpen(true)}
      >
        <Icon icon='mdi:attachment' />
      </Button>
    </Box>
  )
}

export default AttachFile
