import { createStore, createEffect } from 'effector'

const setLocale = createEffect((payload: string) => payload)

const locale = createStore<{ language: string }>({ language: 'ru' }).on(setLocale, (state: any, payload: string) => {
  return { ...state, language: payload }
})

export { setLocale }

export default locale
